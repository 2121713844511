h1, h2, h3, h4, h5 {
  color: #000;
  font-family: "Raleway", sans-serif;
  text-align: center; }

h1, h2, h3 {
  font-family: "Raleway", sans-serif;
  font-weight: 300; }
  h1 span, h2 span, h3 span {
    color: white; }

h3 {
  background-color: #000;
  color: #ff0; }

h4, h5 {
  font-weight: 700;
  text-align: left; }

p, li {
  font-weight: 300;
  line-height: 1.4em; }

.main {
  text-align: center; }

.main-title {
  font-size: 2.5em;
  color: #ff0; }
  @media screen and (min-width: 600px) {
    .main-title {
      font-size: 3em; } }
  @media screen and (min-width: 700px) {
    .main-title {
      font-size: 4em; } }
  @media screen and (min-width: 800px) {
    .main-title {
      font-size: 5em; } }

.main-subtitle {
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  letter-spacing: 0.45em;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.65em; }
  @media screen and (min-width: 600px) {
    .main-subtitle {
      font-size: 0.78em;
      left: 3px; } }
  @media screen and (min-width: 700px) {
    .main-subtitle {
      font-size: 1.05em; } }
  @media screen and (min-width: 800px) {
    .main-subtitle {
      font-size: 1.27em; } }

.main-info {
  font-family: "Raleway", sans-serif;
  padding: 1em auto; }
  @media screen and (max-width: 600px) {
    .main-info {
      font-size: 0.8em; } }

.neon {
  text-shadow: 0px 0px 20px #ff0; }
  .neon span {
    text-shadow: 0px 0px 20px #fff; }

@-webkit-keyframes animatedBackground {
  0% {
    background-position: 3888px top; }
  100% {
    background-position: 0 top; } }

@keyframes animatedBackground {
  0% {
    background-position: 3888px top; }
  100% {
    background-position: 0 top; } }

@-webkit-keyframes animatedSeparateImages {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  100% {
    -webkit-transform: translate3d(-3888px, 0, 0);
            transform: translate3d(-3888px, 0, 0); } }

@keyframes animatedSeparateImages {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  100% {
    -webkit-transform: translate3d(-3888px, 0, 0);
            transform: translate3d(-3888px, 0, 0); } }

@-webkit-keyframes animatedSeparateImagesVertical {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  100% {
    -webkit-transform: translate3d(0, -1043px, 0);
            transform: translate3d(0, -1043px, 0); } }

@keyframes animatedSeparateImagesVertical {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  100% {
    -webkit-transform: translate3d(0, -1043px, 0);
            transform: translate3d(0, -1043px, 0); } }

/** Common Hex Styles */
.hexagon,
.hexagon:hover {
  -webkit-transition: color 0.3s, background-color 0.3s;
          transition: color 0.3s, background-color 0.3s; }

.registration.hexagon:hover {
  background-color: transparent;
  text-decoration: none; }

.hexagon:hover {
  cursor: pointer;
  background-color: #ff0;
  color: black;
  text-decoration: underline; }

.hexagon > div {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  text-align: center;
  z-index: 1; }

.hex-links {
  text-align: center;
  font-size: 1.4em;
  padding-bottom: 2em; }
  .hex-links li {
    padding: 0 1em; }
  .hex-links a,
  .hex-links a:visited {
    color: #ff0; }
  .hex-links li.register {
    font-weight: 700; }

.footer-hex-links {
  padding-bottom: 1em; }

.registration.hexagon {
  border-left: solid 3px #888;
  border-right: solid 3px #888;
  color: #888;
  cursor: auto; }

/** Large Hex Styles */
.hexagon {
  position: relative;
  width: 100px;
  height: 57.74px;
  margin: 28.87px auto;
  border-left: solid 3px #ff0;
  border-right: solid 3px #ff0; }
  .hexagon > div {
    font-size: 0.9em; }

.hexagon:before,
.hexagon:after {
  content: "";
  position: absolute;
  width: 70.71px;
  height: 70.71px;
  -webkit-transform: scaleY(0.5774) rotate(-45deg);
  transform: scaleY(0.5774) rotate(-45deg);
  background-color: inherit;
  left: 12.6447px; }

.hexagon:before {
  top: -37.3553px;
  border-top: solid 4.2426px #ff0;
  border-right: solid 4.2426px #ff0; }

.hexagon:after {
  bottom: -37.3553px;
  border-bottom: solid 4.2426px #ff0;
  border-left: solid 4.2426px #ff0; }

.registration.hexagon:before {
  border-top: solid 4.2426px #888;
  border-right: solid 4.2426px #888; }

.registration.hexagon:after {
  border-bottom: solid 4.2426px #888;
  border-left: solid 4.2426px #888; }

/** Small Hex Styles */
@media screen and (max-width: 600px) {
  .hexagon {
    position: relative;
    width: 75px;
    height: 43.30px;
    margin: 21.65px 0;
    border-left: solid 2px #ff0;
    border-right: solid 2px #ff0; }
    .hexagon > div {
      font-size: 0.6em; }
  .hexagon:before,
  .hexagon:after {
    content: "";
    position: absolute;
    width: 53.53px;
    height: 53.03px;
    -webkit-transform: scaleY(0.5774) rotate(-45deg);
    transform: scaleY(0.5774) rotate(-45deg);
    background-color: inherit;
    left: 9.5835px; }
  .hexagon:before {
    top: -28.5165px;
    border-top: solid 3.8284px #ff0;
    border-right: solid 3.8284px #ff0; }
  .hexagon:after {
    bottom: -28.0165px;
    border-bottom: solid 3.8284px #ff0;
    border-left: solid 3.8284px #ff0; } }

/** Standard Elements */
html, body {
  overflow-x: hidden; }

img {
  display: block;
  margin: auto; }

a {
  outline: none;
  color: #fff;
  text-decoration: none; }

a:visited {
  color: #fff; }

ul.horizontal {
  list-style-type: none;
  padding-left: 0; }
  ul.horizontal li {
    display: inline-block; }

h5 {
  font-size: 0.9em; }

.clearfix:after {
  content: "";
  display: table;
  clear: both; }

section {
  padding: 2em 3em; }
  @media screen and (max-width: 700px) {
    section {
      padding: 2em 1.5em; } }
  section .header {
    text-align: center;
    font-size: 2em;
    white-space: nowrap; }
    @media screen and (min-width: 700px) {
      section .header {
        font-size: 3em; } }
    section .header h2 {
      width: 0;
      display: inline-block;
      font-size: 0.8em;
      overflow: hidden;
      margin: 0;
      position: relative;
      height: 1.2em;
      padding: 0;
      vertical-align: middle; }
      section .header h2 span {
        color: inherit;
        position: absolute;
        left: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%); }
  section.yellow, section.white {
    color: black; }
    section.yellow h2, section.white h2 {
      color: black; }
    section.yellow h3, section.white h3 {
      color: #ff0;
      background-color: #000;
      text-align: center; }
    section.yellow h4, section.white h4 {
      margin-top: 2em; }
  section.yellow {
    background-color: #ffff2b; }
  section.white {
    background-color: white; }

/** Main Section */
.main {
  background-image: url("../images/skyline.jpg");
  background-size: cover;
  background-color: #000;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-perspective: 800;
  -webkit-transform-style: preserve-3d;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  overflow: hidden; }
  @media screen and (max-width: 440px) {
    .main {
      -webkit-animation: none;
              animation: none; } }
  .main img {
    padding-top: 3em;
    width: 150px; }
    @media screen and (min-width: 600px) {
      .main img {
        width: 200px; } }
    @media screen and (max-height: 800px) {
      .main img {
        height: 200px; } }
    @media screen and (min-width: 700px) {
      .main img {
        width: 250px; } }
    @media screen and (max-height: 900px) {
      .main img {
        height: 250px; } }
    @media screen and (min-width: 800px) {
      .main img {
        width: 350px; } }
  .main .bg-image {
    position: absolute;
    top: 0;
    z-index: -100;
    -webkit-animation: animatedSeparateImages 80s linear infinite;
            animation: animatedSeparateImages 80s linear infinite;
    background-image: url("../images/skyline.jpg");
    background-color: #000;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    height: 1043px;
    width: 500%;
    will-change: transform; }
    @media screen and (max-width: 440px) {
      .main .bg-image {
        -webkit-animation: none;
                animation: none; } }

/** Switch between students and sponsors */
.main-switch {
  position: relative;
  top: 17px;
  font-family: 'Raleway';
  font-size: 0.8em;
  background-color: transparent;
  margin-top: 2em;
  margin-bottom: 4em;
  width: 150px;
  margin-left: auto;
  margin-right: auto; }
  .main-switch a {
    border: 1px solid black;
    -webkit-transition: background-color 0.3s;
            transition: background-color 0.3s;
    color: black;
    padding: 0.5em; }
    .main-switch a:visited {
      color: #b8b8b8; }
    .main-switch a:hover {
      -webkit-transition: color 0.3s, background-color 0.3s;
              transition: color 0.3s, background-color 0.3s;
      background-color: #b8b8b8;
      color: #000;
      cursor: pointer; }
  .main-switch a.students-switch {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-right: none; }
    .students-flag .main-switch a.students-switch {
      background-color: black;
      color: yellow; }
    .students-flag .main-switch a.students-switch:after {
      content: ' <'; }
  .main-switch a.sponsors-switch {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-left: none; }
    .sponsors-flag .main-switch a.sponsors-switch {
      background-color: black;
      color: yellow; }
    .sponsors-flag .main-switch a.sponsors-switch:before {
      content: '> '; }

.main-title {
  margin-bottom: 0;
  margin-top: 0.3em; }

.main-subtitle {
  margin-top: 0;
  position: relative; }
  @media screen and (min-width: 700px) {
    .main-subtitle {
      left: 10px; } }

.main-presented-by {
  text-transform: uppercase;
  background-color: transparent;
  font-size: 0.9;
  font-weight: 500; }
  @media screen and (max-width: 380px) {
    .main-presented-by {
      font-size: 0.6em; } }
  .main-presented-by img {
    padding: 0;
    padding-left: 1em;
    vertical-align: middle;
    display: inline;
    max-width: 200px;
    height: auto; }
    @media screen and (max-width: 380px) {
      .main-presented-by img {
        max-width: 100px; } }

.main-info {
  margin: 3em 0;
  margin-top: 2em;
  color: white; }
  .main-info .date {
    font-family: 'Raleway', sans-serif;
    font-size: 1.5em; }
    .main-info .date span {
      font-weight: 800; }

/** Sponsors Section */
section.supporters,
section.sponsors {
  width: 90%;
  margin: auto; }
  @media screen and (min-width: 1000px) {
    section.supporters,
    section.sponsors {
      width: 75%; } }
  section.supporters img,
  section.sponsors img {
    width: 100%; }

section.supporters .header {
  padding-bottom: 0.5em; }

.platinum,
.gold,
.silver,
.bronze,
.brass {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; }

.logo {
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 2em;
  padding-bottom: 2em; }
  @media screen and (min-width: 600px) {
    .logo {
      margin: 0; } }

.gold .logo {
  width: 40%; }
  @media screen and (min-width: 600px) {
    .gold .logo {
      width: 30%; } }

.silver .logo {
  width: 35%; }
  @media screen and (min-width: 600px) {
    .silver .logo {
      width: 26%; } }

.bronze .logo {
  width: 23%;
  /* Special Cases */ }
  @media screen and (min-width: 600px) {
    .bronze .logo {
      width: 15%; } }
  .bronze .logo.long {
    padding-left: 0;
    padding-right: 0;
    width: 30%; }
    @media screen and (min-width: 600px) {
      .bronze .logo.long {
        width: 25%; } }

.brass .logo {
  width: 23%;
  /* Special Cases */ }
  @media screen and (min-width: 600px) {
    .brass .logo {
      width: 12%; } }
  .brass .logo.long {
    padding-left: 0;
    padding-right: 0;
    width: 30%; }
    @media screen and (min-width: 600px) {
      .brass .logo.long {
        width: 25%; } }

.bronze {
  margin-bottom: 0; }

.mlh-badge {
  display: block; }
  @media screen and (max-width: 450px) {
    .mlh-badge {
      display: none; } }

/** About Section */
section.about {
  background-image: url("../images/hexLowerFade.png");
  background-repeat: no-repeat;
  background-position: bottom right; }
  section.about h2.slogan {
    text-align: center;
    font-weight: 500;
    font-style: italic; }
  section.about a {
    text-decoration: underline;
    color: #222; }

/** MISC */
.text-section {
  border-left: 5px solid black;
  padding-left: 1em;
  margin: 2em auto; }

.container {
  max-width: 990px;
  margin: auto; }

/** Sponsor Student Toggle */
.students-only,
.sponsors-only {
  -webkit-transition: opacity 1s;
          transition: opacity 1s;
  display: none;
  opacity: 0; }

.students-flag .students-only,
.sponsors-flag .sponsors-only {
  -webkit-transition: opacity 1s;
          transition: opacity 1s;
  display: block;
  opacity: 1; }

@-webkit-keyframes hide {
  0% {
    top: 0; }
  100% {
    height: 0;
    opacity: 0; } }

@keyframes hide {
  0% {
    top: 0; }
  100% {
    height: 0;
    opacity: 0; } }

.faqs {
  background-color: white; }
  .faqs .header {
    margin-bottom: 0.7em; }
  .faqs h5 {
    margin: 0;
    padding: 1em 0;
    cursor: pointer; }
    .faqs h5:before {
      content: '+';
      font-size: 2em;
      font-weight: 500;
      position: absolute;
      left: 0;
      top: 5px; }
  .faqs .question {
    border-bottom: 1px solid black;
    padding-left: 1.1em;
    position: relative;
    overflow: hidden; }
    .faqs .question ul {
      margin-top: 0; }
    .faqs .question a {
      color: #aaa; }
      .faqs .question a:hover {
        color: inherit; }
    .faqs .question p, .faqs .question > ul {
      margin: 0;
      padding-bottom: 1em; }
  .faqs .question.open h5:before {
    content: '-';
    top: 3px; }
  .faqs .half {
    width: 50%;
    float: right; }
    .faqs .half:nth-of-type(even) .question {
      margin-right: 1em; }
    @media screen and (max-width: 700px) {
      .faqs .half {
        float: none;
        width: 100%; } }
  .faqs .sponsorship-guide {
    position: relative; }
    .faqs .sponsorship-guide a {
      position: absolute;
      top: 0;
      right: 0; }
      .faqs .sponsorship-guide a h4 {
        margin: 0;
        color: #ff0;
        background-color: black; }
        .faqs .sponsorship-guide a h4:before {
          content: '> ';
          color: black;
          background-color: white; }

.icon-menu {
  display: none;
  top: .5em;
  right: .5em; }
  .icon-menu svg {
    height: 35px;
    width: 35px; }
  @media screen and (max-width: 1300px) {
    .icon-menu {
      display: block; } }

.social {
  position: fixed;
  z-index: 1;
  margin: 1em 0; }
  .social li {
    padding-left: 2em; }
  @media screen and (max-width: 1300px) {
    .social a {
      display: none; } }
  .social .icon-social svg {
    height: 18px;
    width: 18px; }

footer {
  padding: 2em 0;
  background: black; }
  footer h3 {
    margin: 0; }
    footer h3 span {
      text-decoration: underline;
      color: #ff0; }
  footer h4 {
    color: #cccc00;
    font-weight: 100;
    text-align: center; }
    footer h4 a:visited {
      color: #cccc00; }
  footer .footer-logo {
    display: inline-block;
    vertical-align: middle; }
  footer .footer-hex-links {
    padding: 0; }

.apiList-header {
  background-color: #000;
  background-position: 100px center; }
  .apiList-header .bg-image {
    top: -234px;
    left: -1223px;
    height: 1500%;
    -webkit-animation: animatedSeparateImagesVertical 40s linear infinite;
            animation: animatedSeparateImagesVertical 40s linear infinite; }

.apiList .apiList-container h2 {
  text-align: left; }

.apiList .apiList-container h4 {
  text-align: center; }

.choice {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: black;
  z-index: 1; }
  .choice svg {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    max-width: 100%;
    max-height: 60%; }
  .choice #wires {
    opacity: 0; }
  .choice #power-line,
  .choice #power-circle,
  .choice #cover {
    cursor: pointer;
    opacity: 1; }
  .choice svg.blurred {
    -webkit-filter: blur(20px);
            filter: blur(20px);
    z-index: -1; }
  .choice .hexagon {
    position: absolute;
    background-color: #111;
    opacity: 0; }
  .choice .hexagon,
  .choice .hexagon:before,
  .choice .hexagon:after {
    border-color: #111; }

.logos {
  height: 350px;
  margin-bottom: 3em; }
  @media screen and (max-width: 450px) {
    .logos {
      margin-bottom: 1em; } }
  .logos svg {
    height: 350px; }

.choice_container {
  position: relative;
  margin: auto;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  max-height: 90%; }

.choice_buttons {
  border: 2px solid white;
  border-radius: 0.5em;
  display: inline-block;
  text-align: center;
  font-family: 'Raleway';
  text-transform: uppercase;
  opacity: 0;
  margin: auto;
  cursor: pointer;
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%); }
  @media screen and (max-width: 450px) {
    .choice_buttons {
      margin: auto;
      left: auto;
      -webkit-transform: none;
              transform: none;
      display: block;
      max-width: 90%; } }

.choice_button {
  -webkit-transition: color 0.3s, background 0.3s;
          transition: color 0.3s, background 0.3s;
  color: white;
  padding: 1em 4em;
  float: left;
  display: block; }
  @media screen and (max-width: 450px) {
    .choice_button {
      float: none;
      padding: 1em 0; } }
  .choice_button:hover {
    -webkit-transition: color 0.3s, background 0.3s;
            transition: color 0.3s, background 0.3s;
    background: white;
    color: black; }
  .choice_button:last-child {
    border-left: 1px solid white; }
    @media screen and (max-width: 450px) {
      .choice_button:last-child {
        border: none;
        border-top: 1px solid white; } }

.choice_button.sponsor:before {
  background: white;
  height: 100%;
  width: 10%; }

.showcase h1 {
  font-size: 4em;
  margin-bottom: 0; }

.showcase hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #aaa;
  margin: 0;
  margin-bottom: 1em;
  padding: 0; }

.showcase .subtitle {
  margin-top: 0;
  margin-bottom: 3em;
  text-transform: uppercase;
  letter-spacing: 1.8em;
  padding-left: 2em; }

.showcase_container {
  max-width: 68em;
  margin: auto; }

.showcase_sponsor-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  margin: 5em 0; }
  .showcase_sponsor-row > * {
    padding: 0 3em; }
  .showcase_sponsor-row img {
    max-width: 90%;
    max-height: 100%; }

.showcase_tier {
  font-family: 'Raleway';
  text-transform: uppercase;
  color: #aaa; }

#map, .map-container {
  height: 500px; }
  @media screen and (max-height: 550px) {
    #map, .map-container {
      height: 350px; } }

section.map {
  padding-left: 0;
  padding-right: 0; }

.map-container {
  margin-top: 2em;
  position: relative; }
  .map-container .map-mouse-cover {
    z-index: 2;
    position: absolute;
    height: 100%;
    width: 100%;
    cursor: pointer; }
